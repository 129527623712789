import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import cc from 'classcat'
import loadable from '@loadable/component'

import CtaTextOverlay from '../components/CtaTextOverlay'
import LazyImage from '../../../../../components/LazyImage'
import Swiper from './Swiper'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './ContentSliderSettings'))

export class ContentSliderPluginRaw extends Component {
  static propTypes = {
    config: PropTypes.shape({
      storeFile: PropTypes.func.isRequired,
      imageUrl: PropTypes.func,
      previewUrl: PropTypes.func,
    }).isRequired,
    editorView: PropTypes.bool.isRequired,
    editorMode: PropTypes.oneOf(['view', 'edit']).isRequired,
    isMultiColumn: PropTypes.bool.isRequired,
    data: ImmutablePropTypes.map.isRequired,
    onDataChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    recommendedImageDimensions: ImmutablePropTypes.map.isRequired,
  }

  static get defaultProps() {
    return {
      data: Immutable.fromJS({
        images: [],
        autoPlay: true,
        slideInterval: 5000,
        showBullets: true,
        selectedImageIndex: 0,
      }),
    }
  }

  static get actionBarButtons() {
    return {
      edit: true,
    }
  }

  state = {
    selectedIndex: 0,
    isSettingActive: false,
  }

  ref = createRef()

  nextButtonRef = createRef()

  prevButtonRef = createRef()

  swiperContainerRef = createRef()

  onPluginActiveStateChange = (isSettingActive) => {
    this.setState({ isSettingActive })
  }

  render() {
    const { data, t, onEdit, editorMode, editorView, recommendedImageDimensions } = this.props
    const recommendedImageWidth = recommendedImageDimensions.getIn(['imageSlider', 0])
    const recommendedImageHeight = recommendedImageDimensions.getIn(['imageSlider', 1])

    const pluginActiveClasses = cc([
      'dali-plugin-contentslider',
      {
        'dali-grid-element-highlighted': this.state.isSettingActive,
      },
    ])

    const hasImages = data.get('images')
      ? data
          .get('images')
          .filter((i) => i.get('src'))
          .count() > 0
      : false

    const emptySlider = (
      <div className="dali-grid-element-placeholder">
        <span className="dali-plugin-contentslider-placeholder" />
        <div className="dali-plugin-contentslider-info">
          {t('components.contentSliderComponent.imageInfo', { recommendedImageWidth, recommendedImageHeight })}
        </div>
        <label className="dali-plugin-contentslider-placeholder-button-add" onClick={onEdit}>
          <span>{t('components.contentSliderComponent.addImagesButton.label')}</span>
        </label>
      </div>
    )

    if (!editorView) {
      return <div className={pluginActiveClasses}>{hasImages ? this.renderSlider() : null}</div>
    } else {
      return (
        <div className={pluginActiveClasses} ref={this.ref}>
          {hasImages ? this.renderSlider() : emptySlider}
          {editorMode === 'edit' ? this.renderSettingsLayer() : null}
        </div>
      )
    }
  }

  renderSlide = (slide) => {
    const { original: src, width, height, alignment, alt, link: href, opentab, index } = slide
    const linkProps = href
      ? {
          href,
          target: opentab ? '_blank' : null,
          rel: opentab ? 'noopener noreferrer' : null,
        }
      : {}

    const textSettingsCta = { ...slide } // it has all the settings

    const Tag = href ? 'a' : 'div'
    return (
      <Tag className="swiper-slide dali-teaser-wrapper" key={index} {...linkProps}>
        <div className="swiper-slide-inner">
          <div className="swiper-slide-inner-image-container" data-image-position={alignment}>
            <LazyImage src={src} width={width} height={height} alt={alt} />
          </div>
        </div>
        <CtaTextOverlay textSettings={textSettingsCta} image={Immutable.fromJS(slide)} />
      </Tag>
    )
  }

  renderSlider() {
    const images = (this.props.data.get('images') || new Immutable.List())
      .map((image) =>
        image.update('original', () =>
          this.props.config.imageUrl ? this.props.config.imageUrl(image.get('src')) : image.get('src'),
        ),
      )
      .toJS()

    const propsOrDefault = (name) => this.props.data.get(name) || ContentSliderPluginRaw.defaultProps.data.get(name)

    const swiperSettings = {
      // see http://idangero.us/swiper/api
      slidesPerView: 1,
      centeredSlides: true,
      direction: 'horizontal',
      loop: !this.props.editorView,
      autoplay: this.props.editorView ? false : { delay: propsOrDefault('slideInterval') },
    }

    const { swiperContainerRef, nextButtonRef, prevButtonRef } = this

    return (
      <Swiper
        {...swiperSettings}
        ref={(swiper) => (this.swiper = swiper)}
        refSet={{ swiperContainerRef, nextButtonRef, prevButtonRef }}
        slides={images}
        renderSwiper={({ slides, slideIdx, slidePrev, slideNext, slideTo }) => (
          <>
            <div className={`swiper-container has-${slides.length}-slides`} ref={this.swiperContainerRef}>
              <div className="swiper-wrapper">{slides.map(this.renderSlide)}</div>
            </div>
            <div className="swiper-button-prev" onClick={() => slidePrev()} />
            <div className="swiper-button-next" onClick={() => slideNext()} />
            <div className="swiper-pagination">
              {slides.length > 1 &&
                slides.map((slide) => (
                  <span
                    className={cc([
                      'swiper-pagination-bullet',
                      {
                        'swiper-pagination-bullet-active': slide.index === slideIdx,
                      },
                    ])}
                    key={slide.index}
                    onClick={() => slideTo(slide.index, 0)}
                  />
                ))}
            </div>
          </>
        )}
      />
    )
  }

  componentDidUpdate() {
    const { selectedIndex } = this.state

    if (this.props.data.getIn(['images', selectedIndex])) {
      if (this.props.editorView) {
        // in case of editor view there is no loop mode anymore and slides are shifted by 1
        this.swiper.gallerySwiper.slideTo(selectedIndex)
      } else {
        this.swiper.gallerySwiper.slideTo(selectedIndex + 1)
      }
    }
  }

  renderSettingsLayer() {
    const { data, config, onDataChange, onSave, onCancel } = this.props
    const { selectedIndex } = this.state
    const formData = data.has('images') ? data : data.set('images', new Immutable.List())

    return (
      <SettingsLayer
        referenceElement={this.ref.current}
        placement="right"
        onActiveStateChange={this.onPluginActiveStateChange}
        onEscapeKeyDown={onCancel}
        className="dali-settingslayer-contentslider"
      >
        {({ renderLayout, updatePosition }) => (
          <SettingsForm
            {...{ config, onDataChange, onSave, onCancel, selectedIndex, renderLayout }}
            data={formData}
            updateSettingsLayerPosition={updatePosition}
            onSelectionChange={(selectedIndex) => {
              // use setState to also trigger a slide change, when s/o uploaded a new image (didUpdate)
              this.setState({ selectedIndex })
            }}
          />
        )}
      </SettingsLayer>
    )
  }
}

export default compose(
  withI18n('interface'),
  translate(),
  connect((state) => ({
    recommendedImageDimensions: state.getIn(['themeMeta', 'recommendedImageDimensions'], Immutable.Map()),
  })),
)(ContentSliderPluginRaw)
